/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-danger */
import React, { FC, useState, useEffect } from "react";
import { Layout } from "@components/Layout";
import styled from "styled-components";
import { Form } from "@components/Form";
import { Heading, Text, Box, Button, Spinner } from "@primitives";
import { graphql, navigate } from "gatsby";
import { FormattedMessage as M } from "gatsby-plugin-intl";
import { useStores } from "@stores";
import { IMarkdownData, ICurrentUserStore } from "../types";
import { observer } from "mobx-react";
import { MY_INFORMATION } from "@constants";
import { getNotificationValues } from "@utils";
import { updateProfilePic } from "../services/api";
import { FileUploader } from "@components/FileUploader";
import { Field } from "react-final-form";
import { Checkbox } from "@components/Checkbox";

interface LoginIndexPageProps {
  data: IMarkdownData;
}

type Phase =
  | "index"
  | "phoneNumber"
  | "language"
  | "profilePic"
  | "contactPermissions"
  | "newsletter"
  | "completed"
  | "test"
  | null;

const StyledForm = styled(Form)`
  label.centerLabels {
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
  .input-wrapper {
    &.group {
      margin: auto;
      &.checkboxGroup {
        margin-bottom: 2rem;
      }
    }
    &.checkbox {
      > div {
        justify-content: center;
      }
    }
  }
  label[for="newsletter"] {
    font-weight: 500;
    justify-content: center;
  }
  &.contactPermissions,
  &.newsletter {
    /* removes the extra margin from the last contact permissions checkbox list */
    > div > div:last-child > div {
      margin-bottom: 0 !important;
    }
  }
  &.completed,
  &.index {
    .form-footer {
      > button {
        width: 100%;
      }
    }
  }
  .form-footer {
    flex-direction: row-reverse;
    width: 100%;
    > button {
      width: 50%;
      min-width: 0;
    }
  }
`;

const WizardContainer = styled(Box)`
  width: calc(100% - 4rem);
`;

const LoginIndexPage: FC<LoginIndexPageProps> = observer(
  ({
    data: {
      markdownRemark: {
        frontmatter: { steps },
      },
    },
  }) => {
    const {
      currentUserStore: {
        currentUser,
        state,
        updateCurrentUser,
        profilePicUrl,
      },
      authStore: { isWizardCompleted, setWizardCompleted },
    }: {
      currentUserStore: ICurrentUserStore;
      authStore: IAuthStore;
    } = useStores();

    const INITIAL_PHASE = "index";
    const [phase, setPhase] = useState(INITIAL_PHASE as Phase);
    const [wizardData, setWizardData] = useState(null);
    const [loading, setLoading] = useState(false);

    const getTranslations = () => steps.find((step) => step.id === phase);

    useEffect(() => {
      if (isWizardCompleted) navigate("/");
    }, [isWizardCompleted]);

    useEffect(() => {
      if (!wizardData && currentUser) {
        setWizardData({
          ...currentUser,
          ...{ notifications: getNotificationValues(currentUser, "fromYh") },
        });
      }
    }, [currentUser]);

    const changePhase = (nextPhase: Phase) => {
      setPhase(nextPhase);
    };

    const customRenderer = ({ id }: any) => {
      switch (id) {
        case "profilePic":
          return (
            <>
              {loading && <Spinner color="mainBlue" />}
              <Field name="profilePic">
                {(props) => (
                  <FileUploader
                    id="upload-profile-picture"
                    onInputChange={onProfilePicChange}
                    buttonLabel={<M id="fields.label.uploadNewProfilePic" />}
                    image
                    imageSize={100}
                    onChange={props.input.onChange}
                    imageAlignment="column"
                    color="black"
                    background="white"
                    imageUrl={profilePicUrl}
                  />
                )}
              </Field>
            </>
          );
        case "newsletter":
          return (
            <Field
              name="newsletter"
              type="checkbox"
              className="newsletter-container"
            >
              {(props) => (
                // @ts-ignore
                <Checkbox
                  label="subscribingToNewsletter"
                  id="newsletter"
                  {...props.input}
                />
              )}
            </Field>
          );
        default:
          return null;
      }
    };

    const onPrevPhaseClick = () => {
      const currentIndex = steps.findIndex((step) => step.id === phase);
      const prevStep = steps[currentIndex - 1];
      setPhase(prevStep.id as Phase);
    };

    const next = (formValues: any, nextPhase: Phase, done: Function) => {
      setWizardData({ ...(wizardData || {}), ...formValues });
      changePhase(nextPhase);
      done();
    };

    const onProfilePicChange = async (e: any) => {
      const file = e.target.files[0];
      setLoading(true);
      await updateProfilePic(file);
      setLoading(false);
    };

    const getPageData = () => {
      const translations = getTranslations() as any;
      let fields = [] as any;
      let onSubmit = null as any;
      let forceEnableSubmit = false;
      let showPrevious = true;

      switch (phase) {
        /**
         * The "index phase"". No forms here.
         */
        default:
        case "index":
          forceEnableSubmit = true;
          showPrevious = false;
          translations.title = currentUser ? (
            <M id="common.greeting" values={{ name: currentUser.first_name }} />
          ) : (
            ""
          );

          onSubmit = (_changedValues: any, done: Function, formValues: any) => {
            next(formValues, "phoneNumber", done);
          };
          break;

        /**
         * PHONE NUMBER INPUT FIELD
         */
        case "phoneNumber":
          fields = [
            {
              id: "phone_number",
              label: "wizard.checkPhoneNumber",
              placeholder: "050 123 5678",
            },
          ];
          forceEnableSubmit = true;

          onSubmit = (_changedValues: any, done: Function, formValues: any) => {
            next(formValues, "language", done);
          };
          break;

        /**
         * LANGUAGE RADIO GROUP FORM
         */
        case "language":
          fields = [
            {
              id: "language",
              type: "radioButtonGroup",
              options: [
                { id: "finnish" },
                { id: "swedish" },
                { id: "english" },
              ],
            },
          ];

          forceEnableSubmit = true;

          onSubmit = (_changedValues: any, done: Function, formValues: any) => {
            next(formValues, "profilePic", done);
          };
          break;

        /**
         * PROFILE PIC UPLOAD FORM
         */
        case "profilePic":
          fields = [
            {
              id: "profilePic",
              label: "uploadYourPic",
              type: "customRender",
            },
          ];

          // User should be able to continue without setting a profile pic?
          forceEnableSubmit = true;

          onSubmit = (_changedValues: any, done: Function, formValues: any) => {
            next(formValues, "contactPermissions", done);
          };
          break;

        /**
         * CONTACT PERMISSIONS CHECKBOX LISTS
         */
        case "contactPermissions":
          fields = MY_INFORMATION.notifications.filter(
            (field) => field.id !== "newsletter"
          );

          // User should be able to continue without setting contact permissions?
          forceEnableSubmit = true;

          onSubmit = (_changedValues: any, done: Function, formValues: any) => {
            next(formValues, "newsletter", done);
          };
          break;

        /**
         * NEWSLETTER CHECKBOX FORM
         */
        case "newsletter":
          fields = MY_INFORMATION.notifications.filter(
            (field) => field.id === "newsletter"
          );
          forceEnableSubmit = true;

          onSubmit = async (
            _data: any,
            callback: Function,
            formValues: any
          ) => {
            const combined = {
              ...(wizardData || {}),
              ...formValues,
              ...{ notifications: getNotificationValues(formValues, "toYh") },
              ...{ email: currentUser.email },
            };

            await updateCurrentUser(combined, callback);

            changePhase("completed");
          };
          break;

        /**
         * COMPLETED
         */
        case "completed":
          forceEnableSubmit = true;
          showPrevious = false;

          onSubmit = async (_data: any, done: Function) => {
            done();
            await setWizardCompleted(currentUser.email);
          };
          break;
      }

      return {
        translations,
        fields,
        onSubmit,
        forceEnableSubmit,
        showPrevious,
      };
    };

    return (
      <Layout twoColumnLayout>
        {state === "Loading" && <Spinner color="mainBlue" />}
        {state !== "Loading" && (
          <WizardContainer p="2" centerContent>
            <Heading mb="2" level="1">
              {getPageData().translations.title}
            </Heading>

            {getPageData().translations.content && (
              <Text mb="2">{getPageData().translations.content}</Text>
            )}

            {getPageData().fields && (
              <StyledForm
                values={wizardData}
                id="login-wizard"
                customRenderer={customRenderer}
                className={`login-wizard ${phase}`}
                fields={getPageData().fields}
                onSubmit={getPageData().onSubmit}
                styles={["centerLabels", "singleColumn"]}
                submitText={getPageData().translations.button}
                translateSubmitText={false}
                forceEnableSubmit={getPageData().forceEnableSubmit}
                footer={
                  getPageData().showPrevious && (
                    <Button
                      background="black"
                      mr="1"
                      id="previous-phase"
                      onClick={onPrevPhaseClick}
                    >
                      <M id="fields.label.previous" />
                    </Button>
                  )
                }
              />
            )}
          </WizardContainer>
        )}
      </Layout>
    );
  }
);

export const pageQuery = graphql`
  query LoginWizardQuery {
    markdownRemark(frontmatter: { path: { eq: "/login/wizard" } }) {
      frontmatter {
        title
        path
        steps {
          button
          content
          id
          title
        }
      }
      html
    }
  }
`;
export default LoginIndexPage;
